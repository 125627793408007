<template>
  <div id="chart" style="width: 450px">
    <apexchart
      @dataPointSelection="dataPointSelection"
      type="pie"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import FirebaseDB from "../../../utils/firebaseDB";
import Constants from "./../../../utils/constants";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  name: "ChartListProduits",
  data: function () {
    return {
      series: [],
      chartOptions: {
        chart: {
          width: "380",
          type: "pie",
        },
        labels: [
          Constants.LIBELLE_POSTES,
          Constants.LIBELLE_PUBLIES,
          Constants.LIBELLE_VALIDES,
          Constants.LIBELLE_INVALIDES,
        ],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        title: {
          text: "Statut du produit",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
        },
      },
    };
  },
  methods: {
    dataPointSelection(event, chartContext, config) {
      localStorage.setItem("markForPostedProduct", null);
      localStorage.setItem("typeForPostedProduct", null);
      switch (config.dataPointIndex) {
        case 0:
          {
            localStorage.setItem("statutForPostedProduct", Constants.POSTE);
          }
          break;
        case 1:
          {
            localStorage.setItem("statutForPostedProduct", Constants.PUBLIE);
          }
          break;
        case 2:
          {
            localStorage.setItem("statutForPostedProduct", Constants.VALIDE);
          }
          break;
        case 3:
          {
            localStorage.setItem("statutForPostedProduct", Constants.INVALIDE);
          }
          break;
        default:
          break;
      }
      this.$router.push({
        name: "PostedProducts",
      });
    },
    async updateChartpie() {
      var generalStats = await FirebaseDB.getGeneralStats();

      generalStats.forEach((stat) => {
        this.series.push(stat.data().number_products_posted);
        this.series.push(stat.data().number_products_published);
        this.series.push(stat.data().number_products_validated);
        this.series.push(stat.data().number_products_invalidated);
      });
    },
  },
  beforeMount() {
    this.updateChartpie();
  },
};
</script>